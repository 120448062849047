<template>

    <div class="p-d-flex p-flex-column container">
        <div class="p-d-flex p-jc-center p-col-12">
            <div v-bind:class="getCssClass((showResultTable || showFilterManagerLayout))" class='p-col-11' style="margin-top: 186px !important;">
                <div class="p-mb-2">
                    <div class="p-mt-5">
                        <div class="box-text"> Portal de Consentimentos </div>
                    </div>
                </div>

                <div class="p-mb-2 p-d-flex p-jc-center" style='height: 41px;'>
                    <div class="p-mt-3" v-if="showErrMessage">
                        <MessagesManager :msg="errMessage"/>
                    </div>
                </div>

                <template v-if="showFilterManagerLayout">
                    <div class="filterConsentsPanel card">
                        <div class="p-grid">
                            
                            <div class="filterConsentsLeftBox p-col-12">

                                <div class="p-col-11" >
                                    <InputText
                                        :disabled='timeoutError'
                                        v-bind:class="{ 'error-field': errorConsentIdField }"
                                        class="filterConsentsInput p-md-6 p-lg-6 p-mr-2 p-mb-2"
                                        type="text"
                                        v-model="filterConsentId"
                                        placeholder="ID Consentimento"
                                        @focus="errorConsentIdField = false; showErrMessage = false"
                                    />
                                    <InputText
                                        :disabled='timeoutError'
                                        format='false'
                                        v-bind:class="{ 'error-field': errorDocumentClientField }"
                                        class="filterConsentsInput p-md-4 p-lg-4 p-mr-2 p-mb-2"
                                        type="number"
                                        v-model="filterDocumentClient"
                                        placeholder="CPF Cliente"
                                        @focus="errorDocumentClientField = false; showErrMessage = false"
                                    />
                                    <Button
                                        :disabled='timeoutError'
                                        @click="searchFilterConsent(1)"
                                        type="button"
                                        icon="pi pi-search"
                                        class="p-ml-3 filterConsentsButton"
                                    />
                                    <InputText
                                        :disabled='timeoutError'
                                        format='false'
                                        v-bind:class="{ 'error-field': errorDocumentField }"
                                        class="filterConsentsInput p-md-4 p-lg-4 p-mr-2 p-mb-2"
                                        type="number"
                                        v-model="filterDocument"
                                        placeholder="CPF/CNPJ Pagador"
                                        @focus="errorDocumentField = false; showErrMessage = false"
                                    />
                                    <InputText
                                        :disabled='timeoutError'
                                        format='false'
                                        v-bind:class="{ 'error-field': errorIssuerField }"
                                        class="filterConsentsInput filterW122 p-md-2 p-lg-2 p-mr-2 p-mb-2"
                                        type="text"
                                        v-model="filterIssuer"
                                        placeholder="Agência"
                                        @focus="errorIssuerField = false; showErrMessage = false"
                                    />
                                    <InputText
                                        :disabled='timeoutError'
                                        format='false'
                                        v-bind:class="{ 'error-field': errorAccountPayerField }"
                                        class="filterConsentsInput p-md-3 p-lg-3 p-mr-2 p-mb-2"
                                        type="text"
                                        v-model="filterAccountPayer"
                                        placeholder="Conta Pagador"
                                        @focus="errorAccountPayerField = false; showErrMessage = false"
                                    />
                                    <InputText
                                        :disabled='timeoutError'
                                        format='false'
                                        v-bind:class="{ 'error-field': errorDocumentCreditorField }"
                                        class="filterConsentsInput p-md-4 p-lg-4 p-mr-3 p-mb-2"
                                        type="number"
                                        v-model="filterDocumentCreditor"
                                        placeholder="CPF/CNPJ Beneficiário"
                                        @focus="errorDocumentCreditorField = false; showErrMessage = false"
                                    />
                                    <span class="filterConsentsInputText p-col-12 p-md-5 p-lg-5"> Selecione o período: </span>
                                    <InputMask
                                        :disabled='timeoutError'
                                        v-bind:class="{ 'error-field': errorFormatStartDateField }"
                                        class="filterConsentsInput filterW122 p-md-2 p-lg-2 p-mr-2 p-mb-2"
                                        mask="99/99/9999"
                                        v-model="filterFormatStartDate"
                                        placeholder="De"
                                        slotChar="dd/mm/aaaa"
                                        @focus="errorFormatStartDateField = false; showErrMessage = false"
                                    />
                                    <InputMask
                                        :disabled='timeoutError'
                                        v-bind:class="{ 'error-field': errorFormatEndDateField }"
                                        class="filterConsentsInput filterW122 p-md-2 p-lg-2 p-mr-2 p-mb-2"
                                        mask="99/99/9999"
                                        v-model="filterFormatEndDate"
                                        placeholder="Até"
                                        slotChar="dd/mm/aaaa"
                                        @focus="errorFormatEndDateField = false; showErrMessage = false"
                                    />
                                    <span class="p-col-1 p-md-1 p-lg-1" style="display: inline-block; padding: 0px" :disabled='timeoutError' @click="$refs.calendarManagerModal.enable"><i class="pi pi-calendar"/></span>
                                </div>
                                
                            </div>

                            <div class="filterConsentsLeftBox p-col-12">

                                <div class="filterConsentsStatusTitle">
                                    <span>Consulta por Status de Consentimento:</span>
                                </div>

                                <div>
                                    <span class="filterConsentsStatus onMobileBreakLine">
                                        <Checkbox :disabled='timeoutError' id="filterAwaitingCheck" class="checkPManager" v-model="filterAwaiting" :binary="true"/>
                                        <label for="filterAwaitingCheck">Aguardando autorização</label>
                                    </span>
                                    <span class="filterConsentsStatus onMobileBreakLine">
                                        <Checkbox :disabled='timeoutError' id="filterAuthorizedCheck" class="checkPManager" v-model="filterAuthorized" :binary="true"/>
                                        <label for="filterAuthorizedCheck">Autorizado</label>
                                    </span>
                                    <span class="filterConsentsStatus onMobileBreakLine">
                                        <Checkbox :disabled='timeoutError' id="filterRejectedCheck" class="checkPManager" v-model="filterRejected" :binary="true"/>
                                        <label for="filterRejectedCheck">Rejeitado</label>
                                    </span>
                                    <span class="filterConsentsStatus onMobileBreakLine">
                                        <Checkbox :disabled='timeoutError' id="filterConsumedCheck" class="checkPManager" v-model="filterConsumed" :binary="true"/>
                                        <label for="filterConsumedCheck">Consumido</label>
                                    </span>
                                    <!-- <Button :disabled='timeoutError' @click="clearFilterConsent()" class="filterClear font-bold-red"> Limpar todos os filtros <i class="pi pi-times filterConsentsClearIcon"/></Button> -->
                                </div>
                            </div>
                            
                            <div class="filterConsentsLeftBox p-col-12">

                                <div class="filterConsentsStatusTitle">
                                    <span>Consentimentos:</span>
                                </div>

                                <div>
                                    <span class="filterConsentsStatus onMobileBreakLine">
                                        <RadioButton 
                                          :disabled='timeoutError' 
                                          id="radioConsentsAll" 
                                          class="radioPManager" 
                                          value="all"
                                          v-model="typeConsentSelected" 
                                        />
                                        <label for="radioConsentsAll">Todos</label>
                                    </span>
                                    <span class="filterConsentsStatus onMobileBreakLine">
                                        <RadioButton 
                                            :disabled='timeoutError' 
                                            id="radioConsentsScheduled" 
                                            class="radioPManager" 
                                            value="scheduled"
                                            v-model="typeConsentSelected"
                                          />
                                        <label for="radioConsentsScheduled">Agendados</label>
                                    </span>
                                   
                                    <Button :disabled='timeoutError' @click="clearFilterConsent()" class="filterClear font-bold-red"> Limpar todos os filtros <i class="pi pi-times filterConsentsClearIcon"/></Button>
                                </div>
                            </div>
                            <div class="mobileShow filterConsentsRightBoxMobile p-col-12" style="text-align: center;">
                                <Button @click="searchFilterConsent(1)" type="button" icon="pi pi-search" class="p-ml-3 filterConsentsButton"/>
                            </div>
                            
                        </div>
                    </div>
                </template>

                <div v-show="showResultTable || showFilterManagerLayout">
                    <div class="p-mb-2">
                        <div class="p-mt-5">
                            <div class="box-text"> Resultados </div>
                        </div>
                    </div>
                    <div class="p-mb-2">
                        <div class="p-mt-5 p-mb-1">
                            <DataTable class="dataTableManagerConsents" :value="consents" sortMode="multiple" responsiveLayout="scroll">
                                <Column field="debtorDocument" header="CPF Cliente" :sortable="true" :bodyStyle="bodyStyle" :headerStyle="headerStyle"></Column>
                                <Column field="creditor" header="Beneficiário" :sortable="true" :bodyStyle="bodyStyle" :headerStyle="headerStyle"></Column>
                                <Column field="value" header="Valor" :sortable="true" :bodyStyle="bodyStyle" :headerStyle="headerStyle">
                                    <template #body="slotProps">
                                    {{parseCurrency('pt-BR', 'BRL', slotProps.data.value)}}
                                    </template>
                                </Column>
                                <Column header="Status" :bodyStyle="bodyStyle" :headerStyle="headerStyle" :sortable="true">
                                    <template #body="slotProps">
                                         <div class="aligner" style="display: flex;align-items: center;">
                                            <div class="icon" style="margin-right: 5px;"><i v-bind:class="getStatusIcon(slotProps.data.status)"></i></div>
                                            <div :class="'status status-' + parseStatus(slotProps.data.status)">{{getStatusName(slotProps.data.status)}}</div>
                                          </div>
                                    </template>
                                </Column>
                                <Column field="statusUpdate" header="Data de criação" :bodyStyle="bodyStyle" :headerStyle="headerStyle" :sortable="false"></Column>
                                <Column :exportable="false" field="id" :bodyStyle="bodyStyle" :headerStyle="headerStyle">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-search-plus" class="p-button-rounded p-button-secondary p-button-text p-button-lg" style="margin-left:60%" @click="detailConsent(slotProps.data.id)"/>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>

                        <div v-if="showFilterManagerLayout && consents.length > 0" style="text-align: center; margin-bottom: 20px;">
                            <div class="paginatorWrapper">
                                
                                <div class="paginatorContent paginatorLeft" v-if="actualPage > 1" @click="getPreviousPage()">
                                    <svg class="paginatorIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#000000" fill-rule="evenodd" clip-rule="evenodd" d="M8.14645 11.6465L14.1464 5.64648L15 6.00004V18L14.1464 18.3536L8.14645 12.3536V11.6465Z"/>
                                    </svg>
                                </div>

                                <div class="paginatorContent paginatorRight" v-if="actualPage < maxPages" @click="getNextPage()">
                                    <svg class="paginatorIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#000000" fill-rule="evenodd" clip-rule="evenodd" d="M16.3536 11.6465L10.3536 5.64648L9.5 6.00004V18L10.3536 18.3536L16.3536 12.3536V11.6465Z"/>
                                    </svg>
                                </div>

                                <div class="paginatorContent paginatorCenter">
                                    <span class="paginatorText">
                                        <span class="paginatorLeftText">{{actualPage}}</span> <span class="paginatorCenterText">/</span> <span class="paginatorRightText">{{maxPages}}</span>
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>

    <FilterManagerCalendarModal
        ref="calendarManagerModal"
        @action="setDinamycDays()"
    />

    <FilterManagerErrorModal
        ref="filterManagerErrorModal"
        :modalMessage1="filterErrorMessage1"
        :modalMessage2="filterErrorMessage2"
        :showButtonOk="filterErrorShowButtonOk"
    />

</template>

<script>
import {
  getConsentById,
  ConsultConsentErrors
} from "@/services/consult-consent-service";
import {
  getWithExpiry,
  setWithExpiry,
  FetchStates,
  GetConsentCssClass,
  ConsentStatus
} from "@/util/util.js";
import moment from "moment";

const filterTokenExpiryIn = 300000;

const consentPagePaginatorSize = 10;
const TECBAN_TIMEOUT = 30000;
const MAX_TIMEOUT = 300000;

/* filter timeout controllers */
const FETCH_TIMEOUT = 30000;
var didTimeOut = false;
var timeoutActiveTokens = [];
/* */

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {

  data() {
    return {
      timeoutError: false,
      consentId: "",
      showResultTable: false,
      showErrMessage: false,
      errMessage: "",
      consents: [],
      headerStyle:
        "background: #ECEBED; color: #808080; font-style: normal;font-weight: bold;font-size: 14px;line-height: 24px;border-top: 1px solid #B9B9B9;border-bottom: 1px solid #B9B9B9;box-sizing: border-box;border-radius: 4px;",
      bodyStyle: "border:none",
      inputTextIcon: "pi pi-pencil",
      inputTextStyle:
        "min-width:390px;min-height:56px; border-radius:8px;border: 1px solid #828282;",

      showFilterManagerLayout: false,

      filterIfId: "",
      filterConsentId: "",
      errorConsentIdField: false,
      filterDocumentClient: "",
      errorDocumentClientField: false,
      filterAccount: "",
      filterIssuer: "",
      errorIssuerField: false,
      filterAccountPayer: "",
      errorAccountPayerField: false,
      filterDocumentCreditor: "",
      errorDocumentCreditorField: false,
      arrayIdAccounts: [],
      filterDocument: "",
      errorDocumentField: false,
      filterListStatus: [],
      filterAwaiting: false,
      filterAuthorized: false,
      filterRejected: false,
      filterConsumed: false,
      filterPageNumber: 1,
      filterFormatStartDate: "",
      errorFormatStartDateField: false,
      filterFormatEndDate: "",
      errorFormatEndDateField: false,
      filterFetchStartDate: "",
      filterFetchtEndDate: "",
      paginationTotalRecords: 0,
      actualPage: 1,
      maxPages: 1,
      filterErrorMessage1: "",
      filterErrorMessage2: "",
      filterErrorShowButtonOk: true,
      typeConsentSelected: 'all'
    };
  },

  methods: {

    async filterTimeoutFunction(tokenPos) {

      await sleep(FETCH_TIMEOUT);
      if (timeoutActiveTokens[tokenPos]) {
        didTimeOut = true;
        return "request timed out";
      }
      return "Not Timed out";
    },

    getStatusName(status) {
      switch (status) {
        case ConsentStatus.WAITING:
          return "Aguardando autorização";
        case ConsentStatus.ACCEPTED:
          return "Autorizado";
        case ConsentStatus.REJECTED:
          return "Rejeitado";
        case ConsentStatus.CONSUMED:
          return "Consumido";
      }
    },

    parseStatus(status) {
      return GetConsentCssClass(status);
    },

    getCssClass(showTable) {
      return showTable
        ? "p-d-flex p-flex-column box-table"
        : "p-d-flex p-flex-column box";
    },

    deleteConsentConsult() {
      this.consentId = "";
      this.clearConsent();
    },

    isEmpty(obj) {
      if (obj === "undefined" || obj == null) return true;

      if (typeof obj === "string") return obj.replace(/\s/g, "").length < 1;

      return false;
    },

    clearConsent() {
      this.consents = [];
    },

    getFormatedDateddmmyyyy(date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("000" + date.getFullYear()).slice(-4)
      );
    },

    getFormatedDateyyyymmdd(date, separatorChar) {
      return (
        ("000" + date.getFullYear()).slice(-4) +
        separatorChar +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        separatorChar +
        ("0" + date.getDate()).slice(-2)
      );
    },
    isValidDateddmmyyyy(date) {
      if (
        date == null ||
        date == "" ||
        date.includes("d") ||
        date.includes("m") ||
        date.includes("a")
      )
        return false;
      if (!moment(date, "DD/MM/YYYY", true).isValid()) return false;
      return true;
    },
    setDinamycDays() {
      this.filterFormatStartDate = this.getFormatedDateddmmyyyy(
        this.$refs.calendarManagerModal.dateModelStart
      );
      this.filterFormatEndDate = this.getFormatedDateddmmyyyy(
        this.$refs.calendarManagerModal.dateModelEnd
      );
    },

    /* Get Consents By filter and pagination functions*/
    async fetchGetConsentsManagerFilter(
      ifId,
      consentId,
      cpfNumber,
      accounts,
      consentStatus,
      dateInit,
      dateEnd,
      pageNumber,
      cpfCnpjFavorecido,
      consentScheduled
    ) {
      let id_token = sessionStorage.getItem("MANAGER_TECBAN_ID_TOKEN");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + id_token);
      myHeaders.append("Content-Type", "application/json");
      let rawTmp = {};
      rawTmp["ifId"] = ifId;
      if (consentId) {
        rawTmp["consentId"] = consentId;
      }
      if (cpfNumber) {
        rawTmp["cpfNumber"] = cpfNumber;
      }
      if (consentStatus) {
        rawTmp["consentStatus"] = consentStatus;
      }
      if (accounts) {
        rawTmp["accounts"] = accounts;
      }
      if (cpfCnpjFavorecido) {
        rawTmp["cpfCnpjFavorecido"] = cpfCnpjFavorecido;
      }
      if (dateInit && dateEnd) {
        rawTmp["dateInit"] = dateInit;
        rawTmp["dateEnd"] = dateEnd;
      }
      if (consentScheduled) {
        rawTmp["scheduled"] = consentScheduled==='scheduled' ? true : false;
      }
      rawTmp["pageSize"] = consentPagePaginatorSize;
      rawTmp["pageNumber"] = pageNumber;

      var raw = JSON.stringify(rawTmp);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
        if (didTimeOut) throw "request timed out";
        let response = await fetch(
          `${process.env.VUE_APP_CONSENTS}/consent/search-manager`,
          requestOptions
        );
        if (!response.ok || response.errorMsg != null) {
          console.log(response);
          console.log(response.errorMsg);
          throw "consent filter status error";
        }

        if (didTimeOut) throw "request timed out";
        let consents = await response.json();

        if (consents.data == null) return [];

        return consents;
      } catch (error) {
        console.log(error);
        return "consent filter error";
      }
    },

    async getFilterConsentsArray() {
      var filteredConsents = null;

      // make requisition with correct timeout function
      timeoutActiveTokens.push(true);
      let tokenPos = timeoutActiveTokens.length - 1;
      filteredConsents = await Promise.race([
        this.filterTimeoutFunction(tokenPos),
        this.fetchGetConsentsManagerFilter(
          this.filterIfId,
          this.filterConsentId,
          this.filterDocumentClient,
          this.arrayIdAccounts,
          this.filterListStatus,
          this.filterFetchStartDate,
          this.filterFetchEndDate,
          this.filterPageNumber,
          this.filterDocumentCreditor,
          this.typeConsentSelected
        )
      ]);
      if (filteredConsents == "request timed out") throw filteredConsents;
      timeoutActiveTokens[tokenPos] = false;

      if (filteredConsents == "consent filter error") throw filteredConsents;

      if (
        filteredConsents == null ||
        filteredConsents == undefined ||
        filteredConsents.data == null ||
        filteredConsents.data == undefined ||
        filteredConsents.data.length == 0
      )
        throw "empty consent array";

      let paginationTotalRecordsTmp = filteredConsents.totalRecords;
      let consentArray = Array.from(filteredConsents.data);
      
      consentArray.forEach(c => {
        if (
          !this.isEmpty(c.consentBody.data.creditor) &&
          !this.isEmpty(c.consentBody.data.payment)
        ) {
          let tmp = {
            id: c.consentBody.data.consentId,
            debtor: "",
            debtorDocument: "",
            creditor: c.consentBody.data.creditor.name,
            value: c.consentBody.data.payment.amount,
            status: c.consentBody.data.status,
            statusUpdate: this.convertUtcDateToString(
              c.consentBody.data.statusUpdateDateTime,
              "pt-BR",
              true
            ),
            visualize: true
          };

          // get payer document
          tmp.debtorDocument =
            c.consentBody.data.loggedUser.document.identification;
          this.consents.push(tmp);
        }
      });

      if (this.consents.length <= 0) {
        this.cleanSessionActualFilterConsents();
        throw "empty consent array";
      } else {
        this.paginationTotalRecords = paginationTotalRecordsTmp;
        this.updatePageNumbers(
          this.filterPageNumber,
          Math.ceil(this.paginationTotalRecords / consentPagePaginatorSize)
        );
        this.setSessionActualFilterConsents();
      }
    },

    validateInputTexts() {
      let valid = true;
      if (this.filterConsentId) {
        //eslint-disable-next-line
        if (
          this.filterConsentId.length > 256 ||
          !/^urn:[a-zA-Z0-9][a-zA-Z0-9-]{0,31}:[a-zA-Z0-9()+,\-.:=@;$_!*'%\/?#]+$/.test( /* eslint-disable-line */
            this.filterConsentId
          )
        ) {
          this.errorConsentIdField = true;
          valid = false;
        }
      }
      if (this.filterDocumentClient) {
        if (!this.cpfIfValid(this.filterDocumentClient)) {
          this.errorDocumentClientField = true;
          valid = false;
        }
      }
      if (this.filterDocument) {
        const cpfOrCnpj = this.filterDocument.length === 14 ? "cnpj" : "cpf";
        if (
          (cpfOrCnpj === "cpf" && !this.cpfIfValid(this.filterDocument)) ||
          (cpfOrCnpj === "cnpj" && !this.cnpjIsValid(this.filterDocument))
        ) {
          this.errorDocumentField = true;
          valid = false;
        }
      }
      if (this.filterDocumentCreditor) {
        const cpfOrCnpj =
          this.filterDocumentCreditor.length === 14 ? "cnpj" : "cpf";
        if (
          (cpfOrCnpj === "cpf" &&
            !this.cpfIfValid(this.filterDocumentCreditor)) ||
          (cpfOrCnpj === "cnpj" &&
            !this.cnpjIsValid(this.filterDocumentCreditor))
        ) {
          this.errorDocumentCreditorField = true;
          valid = false;
        }
      }
      if (this.filterIssuer) {
        if (!this.filterAccountPayer) {
          this.errorAccountPayerField = true;
          valid = false;
          throw "account empty";
        }
      }
      if (this.filterAccountPayer) {
        if (!this.filterIssuer) {
          this.errorIssuerField = true;
          valid = false;
          throw "issuer empty";
        }
      }
      if (this.filterFormatStartDate) {
        if (!this.isValidDateddmmyyyy(this.filterFormatStartDate)) {
          this.errorFormatStartDateField = true;
          valid = false;
        }
      }
      if (this.filterFormatEndDate) {
        if (!this.isValidDateddmmyyyy(this.filterFormatEndDate)) {
          this.errorFormatEndDateField = true;
          valid = false;
        }
      }
      if (this.filterFormatEndDate < this.filterFormatStartDate) {
        this.errorFormatStartDateField = true;
        this.errorFormatEndDateField = true;
        valid = false;
      }
      return valid;
    },

    parseFilterConsent() {
      this.showErrMessage = false;

      if (
        !this.filterConsentId &&
        !this.filterAccount &&
        !this.filterDocument &&
        !this.filterDocumentClient &&
        !this.filterIssuer &&
        !this.filterAccountPayer &&
        !this.filterDocumentCreditor &&
        !this.filterFormatStartDate &&
        !this.filterFormatEndDate &&
        !this.filterAwaiting &&
        !this.filterAuthorized &&
        !this.filterRejected &&
        !this.filterConsumed
      ) {
        throw "no filter informed";
      }

      const validateFields = this.validateInputTexts();

      if (!validateFields) {
        throw "invalid input";
      }

      this.filterDocument = this.filterDocument
        .replaceAll(".", "")
        .replaceAll("-", "");

      this.filterListStatus = [];

      if (this.filterAwaiting)
        this.filterListStatus.push("AWAITING_AUTHORISATION");
      if (this.filterAuthorized) this.filterListStatus.push("AUTHORISED");
      if (this.filterRejected) this.filterListStatus.push("REJECTED");
      if (this.filterConsumed) this.filterListStatus.push("CONSUMED");

      if (this.filterListStatus.length === 0) throw "empty status list";

      this.filterFetchStartDate = "";
      this.filterFetchEndDate = "";

      if (this.filterFormatStartDate || this.filterFormatEndDate) {
        let startTmp = this.filterFormatStartDate.split("/");
        let dateInit = new Date(`${startTmp[1]}/${startTmp[0]}/${startTmp[2]}`);

        let endTmp = this.filterFormatEndDate.split("/");
        let dateEnd = new Date(`${endTmp[1]}/${endTmp[0]}/${endTmp[2]}`);

        this.filterFetchStartDate =
          dateInit != null
            ? this.getFormatedDateyyyymmdd(new Date(dateInit.getTime()), "-")
            : "";
        this.filterFetchEndDate =
          dateEnd != null
            ? this.getFormatedDateyyyymmdd(new Date(dateEnd.getTime()), "-")
            : "";
      }
    },

    async getAccountsIDs() {
      let accountsFromFilterDocument;
      let accountFormFilterIssuerAndFilterAccountPayer;

      if (this.filterDocument) {
        const param1 = `cpfCnpjPayer=${this.filterDocument}`;
        const param2 = `rel=${
          this.filterDocument.length === 14 ? "CNPJ" : "CPF"
        }`;

        try {
          const requestAccs = await fetch(
            `${
              process.env.VUE_APP_CONSENTS
            }/consent/consent-accounts?${param1}&${param2}`,
            {
              method: "GET",
              redirect: "follow"
            }
          );

          if (requestAccs.status !== 200) {
            throw "response error";
          }

          accountsFromFilterDocument = await requestAccs.json();
        } catch (error) {
          console.warn(error);
          throw "response error";
        }

        if (accountsFromFilterDocument.length === 0) {
          throw "empty consent array";
        }

        if (!this.filterIssuer) {
          this.arrayIdAccounts = accountsFromFilterDocument;
        }
      }

      if (this.filterIssuer && this.filterAccountPayer) {
        const param1 = `issuer=${this.filterIssuer}`;
        const param2 = `number=${this.filterAccountPayer}`;

        try {
          const requestAccs = await fetch(
            `${
              process.env.VUE_APP_CONSENTS
            }/consent/consent-accounts?${param1}&${param2}`,
            {
              method: "GET",
              redirect: "follow"
            }
          );

          if (requestAccs.status !== 200) {
            throw "response error";
          }

          accountFormFilterIssuerAndFilterAccountPayer = await requestAccs.json();
        } catch (error) {
          console.warn(error);
          throw "response error";
        }

        if (accountFormFilterIssuerAndFilterAccountPayer.length === 0) {
          throw "empty consent array";
        }

        if (!this.filterDocument) {
          this.arrayIdAccounts = accountFormFilterIssuerAndFilterAccountPayer;
        }
      }

      if (
        accountsFromFilterDocument &&
        accountFormFilterIssuerAndFilterAccountPayer
      ) {
        this.arrayIdAccounts = [];
        accountsFromFilterDocument.forEach(acc => {
          if (acc === accountFormFilterIssuerAndFilterAccountPayer[0]) {
            this.arrayIdAccounts.push(
              accountFormFilterIssuerAndFilterAccountPayer[0]
            );
          }
          if (this.arrayIdAccounts.length === 0) {
            throw "empty consent array";
          }
        });
      }
    },

    async searchFilterConsent(pageNum) {
      this.arrayIdAccounts = [];
      this.showErrMessage = false;
      this.errorDocumentField = false;
      this.errorDocumentClientField = false;
      this.errorIssuerField = false;
      this.errorAccountPayerField = false;
      this.errorDocumentCreditorField = false;
      this.errorFormatStartDateField = false;
      this.errorFormatEndDateField = false;
      this.filterPageNumber = pageNum;

      try {
        this.clearConsent();
        this.parseFilterConsent();
        await this.getAccountsIDs();
        await this.getFilterConsentsArray();
      } catch (error) {
        console.log(error);

        if (error === "no filter informed") {
          this.displayError(
            "Por favor informe uma opção de filtro para consulta"
          );
        } else if (error === "empty consent array") {
          this.displayError(
            "Nenhum consentimento para ser exibido no momento. Verifique e tente novamente."
          );
        } else if (error === "invalid input") {
          this.displayError("Dados inválidos. Verifique e tente novamente.");
        } else if (error === "account empty") {
          this.displayError(
            "Por favor informe uma conta para prosseguir com a pesquisa."
          );
        } else if (error === "issuer empty") {
          this.displayError(
            "Por favor informe uma agência para prosseguir com a pesquisa"
          );
        } else if (error === "empty status list") {
          this.displayError(
            "Por favor informe um status para prosseguir com a consulta."
          );
        } else if (error === "response error") {
          this.displayError(
            "Houve falha na comunicação. Tente novamente mais tarde."
          );
        } else if (error === "request timed out") {
          this.displayError(
            "Houve falha na comunicação. Tente novamente mais tarde."
          );
        }
      }
    },

    clearFilterConsent() {
      this.clearConsent();
      this.errorConsentIdField = false;
      this.errorDocumentClientField = false;
      this.errorDocumentField = false;
      this.errorIssuerField = false;
      this.errorAccountPayerField = false;
      this.errorDocumentCreditorField = false;
      this.errorFormatStartDateField = false;
      this.errorFormatEndDateField = false;
      this.showErrMessage = false;
      this.filterConsentId = "";
      this.filterAccount = "";
      this.filterIssuer = "";
      this.filterAccountPayer = "";
      this.filterDocumentCreditor = "";
      this.filterDocumentClient = "";
      this.filterDocument = "";
      this.filterListStatus = [];
      this.filterAuthorized = false;
      this.filterAwaiting = false;
      this.filterConsumed = false;
      this.filterRejected = false;
      this.filterFormatStartDate = "";
      this.filterFormatEndDate = "";
      this.filterPageNumber = 1;
      this.paginationTotalRecords = 0;
      this.actualPage = 1;
      this.maxPages = 1;
      this.arrayIdAccounts = [];
      this.typeConsentSelected = 'all';
    },
    setSessionActualFilterConsents() {
      let actualFilterConsents = {
        filterConsentId: this.filterConsentId,
        filterDocument: this.filterDocument,

        filterFormatStartDate: this.filterFormatStartDate,
        filterFormatEndDate: this.filterFormatEndDate,

        filterAwaiting: this.filterAwaiting,
        filterAuthorized: this.filterAuthorized,
        filterRejected: this.filterRejected,

        filterPageNumber: this.filterPageNumber
      };
      setWithExpiry(
        "ACTUAL_FILTER_CONSENTS",
        actualFilterConsents,
        filterTokenExpiryIn
      );
    },
    cleanSessionActualFilterConsents() {
      setWithExpiry("ACTUAL_FILTER_CONSENTS", null, filterTokenExpiryIn);
    },
    loadSessionActualFilterConsents() {
      let actualFilterConsents = getWithExpiry("ACTUAL_FILTER_CONSENTS");

      if (
        actualFilterConsents == null ||
        actualFilterConsents == undefined ||
        actualFilterConsents == {}
      )
        return;

      this.filterConsentId = actualFilterConsents.filterConsentId;
      this.filterDocument = actualFilterConsents.filterDocument;

      this.filterFormatStartDate = actualFilterConsents.filterFormatStartDate;
      this.filterFormatEndDate = actualFilterConsents.filterFormatEndDate;

      this.filterAwaiting = actualFilterConsents.filterAwaiting;
      this.filterAuthorized = actualFilterConsents.filterAuthorized;
      this.filterRejected = actualFilterConsents.filterRejected;

      this.filterPageNumber = actualFilterConsents.filterPageNumber;

      this.searchFilterConsent(this.filterPageNumber);
    },
    updatePageNumbers(actualPage, maxPages) {
      this.actualPage = actualPage;
      this.maxPages = maxPages;
    },
    getPreviousPage() {
      if (this.actualPage > 1) {
        let newActualPage = this.actualPage - 1;
        this.updatePageNumbers(newActualPage, this.maxPages);
        this.searchFilterConsent(newActualPage);
      } else console.log("error, minimun page reached!");
    },
    getNextPage() {
      if (this.actualPage < this.maxPages) {
        let newActualPage = this.actualPage + 1;
        this.updatePageNumbers(newActualPage, this.maxPages);
        this.searchFilterConsent(newActualPage);
      } else console.log("error, maximum page reached!");
    },
    /* */

    /* Old Get Consent funcitons */
    async searchConsent(optionalConsentId) {
      this.clearConsent();

      let token = sessionStorage.getItem("MANAGER_TECBAN_TOKEN");
      let timeout;
      this.inputTextIcon = "pi pi-spin pi-spinner";
      this.consents = [];

      if (optionalConsentId != null && optionalConsentId != undefined)
        this.consentId = optionalConsentId;

      function timeoutPromise() {
        return new Promise(resolve => {
          timeout = setTimeout(() => {
            resolve(FetchStates.TecbanTimeout);
          }, TECBAN_TIMEOUT);
        });
      }

      let consent = await Promise.race([
        timeoutPromise(),
        getConsentById(this.consentId, "consult", token)
      ]);

      if (consent != FetchStates.TecbanTimeout) {
        clearTimeout(timeout);

        if (Object.prototype.hasOwnProperty.call(consent, "getConsentError")) {
          let consentError = consent.getConsentError;
          this.inputTextStyle =
            "min-width:390px;min-height:56px; border-radius:8px;border: 1px solid #FB3434; color: #FB3434";
          this.inputTextIcon = "pi pi-times consentNotFound";

          if (consentError === ConsultConsentErrors.InvalidToken)
            this.errMessage =
              "Usuário não efetuou login. Efetue login e tente novamente.";
          else if (consentError === ConsultConsentErrors.InvalidUrn)
            this.errMessage = "Dados inválidos. Verifique e tente novamente";

          this.showErrMessage = true;
          this.showResultTable = false;
        } else {
          this.showErrMessage = false;
          this.inputTextStyle =
            "min-width:390px;min-height:56px; border-radius:8px;border: 1px solid #828282;";

          if (Object.prototype.hasOwnProperty.call(consent, "errorCode")) {
            this.inputTextStyle =
              "min-width:390px;min-height:56px; border-radius:8px;border: 1px solid #FB3434; color: #FB3434";
            this.inputTextIcon = "pi pi-times consentNotFound";
            this.showErrMessage = true;
            this.showResultTable = false;
            this.errMessage = "Dados inválidos. Verifique e tente novamente";
          } else {
            sessionStorage.setItem("ACTUAL_CONSENT_ID", this.consentId);
            //console.log(consent);
            let tmp = {
              id: consent.data.consentBody.data.consentId,
              debtor: "",
              debtorDocument: "",
              creditor: consent.data.consentBody.data.creditor.name,
              value: consent.data.consentBody.data.payment.amount,
              status: consent.data.consentBody.data.status,
              statusUpdate: "",
              visualize: true
            };

            if (tmp.status == ConsentStatus.WAITING)
              tmp.statusUpdate = this.convertUtcDateToString(
                consent.data.consentBody.data.statusUpdateDateTime,
                "pt-BR",
                true
              );
            else
              tmp.statusUpdate = this.convertUtcDateToString(
                consent.data.consentBody.data.statusUpdateDateTime,
                "pt-BR",
                false
              );

            // get payer document
            if (consent.data.consentBody.data.businessEntity != null)
              tmp.debtorDocument =
                consent.data.consentBody.data.businessEntity.document.identification;
            else
              tmp.debtorDocument =
                consent.data.consentBody.data.loggedUser.document.identification;

            this.consents.push(tmp);
            this.showResultTable = true;
            this.inputTextIcon = "pi pi-pencil";
          }
        }
      } else {
        this.inputTextIcon = "pi pi-pencil";
        this.errMessage =
          "Houve falha na comunicação.Tente novamente mais a tarde.";
        this.showErrMessage = true;
        this.showResultTable = false;
      }
    },

    /* */
    convertUtcDateToString(date, locale, isDateTime) {
      var tmp = new Date(date);
      return isDateTime
        ? tmp.toLocaleString(locale)
        : tmp.toLocaleDateString(locale);
    },

    parseCurrency(locale, type, value) {
      var formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: type,
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });

      return formatter.format(value);
    },

    getStatusIcon(status) {
      switch (status) {
        case ConsentStatus.WAITING:
          return "pi pi-exclamation-triangle status-waiting";
        case ConsentStatus.ACCEPTED:
          return "pi pi-check status-authorised";
        case ConsentStatus.REJECTED:
          return "pi pi-times status-rejected";
        case ConsentStatus.CONSUMED:
          return "pi pi-thumbs-up status-consumed";
      }
    },

    detailConsent(consentId) {
      sessionStorage.setItem("ACTUAL_CONSENT_ID", consentId);
      this.$router.push({ name: "ManagerDetailConsent" });
    },

    checkOnline() {
      if (!navigator.onLine) {
        if (this.showFilterManagerLayout) {
          this.filterErrorShowButtonOk = false;
          this.filterErrorMessage1 = "Houve falha na comunicação.";
          this.filterErrorMessage2 = "Tente novamente mais a tarde.";
          this.$refs.filterManagerErrorModal.enable();
        } else {
          this.inputTextIcon = "pi pi-pencil";
          this.errMessage =
            "Houve falha na comunicação.Tente novamente mais a tarde.";
          this.showErrMessage = true;
          this.showResultTable = false;
        }
      }
    },

    cpfIfValid(cpf) {
      if (typeof cpf !== "string") return false;
      cpf = cpf.replace(/[\s.-]*/gim, "");
      if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      ) {
        return false;
      }
      let soma = 0;
      let resto;
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) return false;
      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(10, 11))) return false;
      return true;
    },

    cnpjIsValid(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");
      if (cnpj === "") return false;
      if (cnpj.length != 14) return false;
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },

    displayError(msg) {
      this.showErrMessage = true;
      this.errMessage = msg;
    }
  },

  mounted() {
    // Assim que a versão do filtro for aprovada será necessário uma refatoração nesta view para remover o fluxo antigo

    this.$root.$refs.mainNavbar.showManagerNavbar();
    let token = Object.prototype.hasOwnProperty.call(
      this.$route.params,
      "value"
    )
      ? this.$route.params.value
      : "invalid";
    let id_token = Object.prototype.hasOwnProperty.call(
      this.$route.params,
      "id_token"
    )
      ? this.$route.params.id_token
      : "invalid";
    let sessionToken = sessionStorage.getItem("MANAGER_TECBAN_TOKEN");
    let actualConsent = sessionStorage.getItem("ACTUAL_CONSENT_ID");
    var maxTimeout;

    if (
      id_token == "invalid" &&
      sessionStorage.getItem("MANAGER_TECBAN_ID_TOKEN") != null &&
      sessionStorage.getItem("MANAGER_TECBAN_ID_TOKEN") != ""
    ) {
      id_token = sessionStorage.getItem("MANAGER_TECBAN_ID_TOKEN");
    }

    this.filterIfId = process.env.VUE_APP_IF_NAME.toLowerCase().trim();
    if (this.filterIfId == "qa") this.filterIfId = "exb";

    this.showFilterManagerLayout =
      process.env.VUE_APP_ENABLE_FILTER_MANAGER !== null &&
      process.env.VUE_APP_ENABLE_FILTER_MANAGER !== "0";

    if (sessionToken != null && sessionToken != undefined) token = sessionToken;

    if (this.showFilterManagerLayout && token === "invalid") {
      this.filterErrorShowButtonOk = false;
      this.filterErrorMessage1 = "Usuário não está logado!";
      this.filterErrorMessage2 = "Efetue o login e tente novamente.";
      this.$refs.filterManagerErrorModal.enable();
    } else if (!this.showFilterManagerLayout && token === "invalid") {
      this.errMessage =
        "Usuário não está logado! Efetue o login e tente novamente.";
      this.showErrMessage = true;
      this.searchDisabled = true;
      return;
    } else {
      sessionStorage.setItem("MANAGER_TECBAN_TOKEN", token);
      sessionStorage.setItem("MANAGER_TECBAN_ID_TOKEN", id_token);
      this.searchDisabled = false;
    }

    if (
      !this.showFilterManagerLayout &&
      actualConsent != null &&
      actualConsent != undefined
    ) {
      //console.log("ConsentimentoGuardado" + actualConsent);
      this.searchConsent(actualConsent);
    } else if (this.showFilterManagerLayout)
      this.loadSessionActualFilterConsents();

    setInterval(() => {
      this.checkOnline();
    }, 3000);

    if (this.showFilterManagerLayout) {
      maxTimeout = setTimeout(() => {
        sessionStorage.removeItem("MANAGER_TECBAN_TOKEN");
        sessionStorage.removeItem("MANAGER_TECBAN_ID_TOKEN");
        sessionStorage.removeItem("ACTUAL_CONSENT_ID");
        // this.filterErrorShowButtonOk = false;
        // this.filterErrorMessage1 = 'Tempo excedido.';
        // this.filterErrorMessage2 = 'Você atingiu o limite de 05 minutos sem executar nenhuma ação.';
        // this.$refs.filterManagerErrorModal.enable();
        this.timeoutError = true;
        this.clearConsent();
        this.displayError(
          "Tempo excedido. Você atingiu o limite de 05 minutos sem executar nenhuma ação."
        );
      }, MAX_TIMEOUT);

      document.addEventListener("click", () => {
        clearTimeout(maxTimeout);
        maxTimeout = setTimeout(() => {
          sessionStorage.removeItem("MANAGER_TECBAN_TOKEN");
          sessionStorage.removeItem("MANAGER_TECBAN_ID_TOKEN");
          sessionStorage.removeItem("ACTUAL_CONSENT_ID");
          // this.filterErrorShowButtonOk = false;
          // this.filterErrorMessage1 = 'Tempo excedido.';
          // this.filterErrorMessage2 = 'Você atingiu o limite de 05 minutos sem executar nenhuma ação.';
          // this.$refs.filterManagerErrorModal.enable();
          this.timeoutError = true;
          this.clearConsent();
          this.displayError(
            "Tempo excedido. Você atingiu o limite de 05 minutos sem executar nenhuma ação."
          );
        }, MAX_TIMEOUT);
      });
    } else {
      maxTimeout = setTimeout(() => {
        sessionStorage.removeItem("MANAGER_TECBAN_TOKEN");
        sessionStorage.removeItem("MANAGER_TECBAN_ID_TOKEN");
        sessionStorage.removeItem("ACTUAL_CONSENT_ID");
        this.errMessage =
          "Você atingiu o limite de 05 minutos sem executar nenhuma ação.";
        this.showErrMessage = true;
        this.searchDisabled = true;
      }, MAX_TIMEOUT);

      document.addEventListener("click", () => {
        clearTimeout(maxTimeout);
        maxTimeout = setTimeout(() => {
          sessionStorage.removeItem("MANAGER_TECBAN_TOKEN");
          sessionStorage.removeItem("MANAGER_TECBAN_ID_TOKEN");
          sessionStorage.removeItem("ACTUAL_CONSENT_ID");
          this.errMessage =
            "Você atingiu o limite de 05 minutos sem executar nenhuma ação.";
          this.showErrMessage = true;
          this.searchDisabled = true;
        }, MAX_TIMEOUT);
      });
    }
  }
};
</script>

<style scoped>
.consentNotFound {
  color: var(--tecban-card-rejected) !important;
  margin-right: 0.5em;
}

.container {
  width: 100%;
  height: 100vh;
  background-color: var(--tecban-back-color1);
  align-items: center;
}

.box {
  background: var(--tecban-default-back-color);
  box-shadow: var(--tecban-default-shadow);
  padding-left: 6em;
  padding-right: 6em;
  padding-top: 1em;
  padding-bottom: 4em;
  box-sizing: border-box;
  border-radius: 8px;
}

.box-table {
  background: var(--tecban-default-back-color);
  box-shadow: var(--tecban-default-shadow);
  box-sizing: border-box;
  border-radius: 8px;
}

.box :active {
  border: none;
}

.box-text {
  color: var(--tecban-text-gray2);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.table-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  background: #ecebed;
  align-items: center;
  color: #808080;
  border: 1px solid #808080;
}

.tr {
  border: none !important;
}

.status-waiting {
  color: var(--tecban-card-waiting) !important;
}

.status-consumed {
  color: var(--tecban-card-consumed) !important;
}

.status-authorised {
  color: var(--tecban-card-authorised) !important;
}

.status-expired {
  color: var(--tecban-card-expired) !important;
}

.status-rejected {
  color: var(--tecban-card-rejected) !important;
}

.status {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.filterConsentsPanel {
  padding: 10px;
}

.filterConsentsLeftBox {
  padding: 0px 15px 0px 15px;
  margin: 15px 0px 0px 0px;
}

.filterConsentsRightBox,
.filterConsentsRightBoxMobile {
  padding: 0px;
  margin: 15px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.filterConsentsRightBoxMobile {
  justify-content: center;
}

.filterConsentsInput {
  border-radius: 8px;
  border: 1px solid #828282;
  padding: 10px;
}

.filterConsentsInputNumber {
  border-radius: 8px;
  border: 1px solid #828282;
}

.filterConsentIdInput {
  width: 55%;
  min-width: 150px;
}

.filterConsentAggInput {
  width: 15%;
  min-width: 85px;
}

.filterConsentAccountInput {
  width: 25%;
  min-width: 100px;
}

.filterConsentsStatus,
.filterConsentsStatusTitle {
  margin: 15px 25px 10px 0px;
}

.filterConsentsButton {
  margin-bottom: 9px;
  min-width: 43px;
  min-height: 43px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px #c4c4c4;
  color: #ffffff;
  background-color: #ed1846;
  border: none;
}

.filterConsentsButton:hover,
.filterConsentsButton:focus {
  background-color: rgb(245, 60, 100);
}
.filterClear {
  margin-right: 98px;
  display: inline-block;
  cursor: pointer;
  background-color: #ecebed;
  border-radius: 8px;
  border: none;
  float: right;
}

.font-bold-red {
  color: #ed1846;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.filterClear:hover,
.filterClear:focus {
  background-color: #808080;
  color: #ecebed;
}

.paginatorWrapper {
  margin: 0 auto;
  display: inline-block;
}
.paginatorContent {
  line-height: 24px;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
.paginatorLeft,
.paginatorRight {
  width: 50px;
  cursor: pointer;
}
.paginatorLeft {
  float: left;
}
.paginatorRight {
  float: right;
}
.paginatorCenter {
  margin: 0 auto;
  width: 100px;
}
.paginatorIcon {
  vertical-align: middle;
}
.paginatorText {
  font-size: 18px;
}
.paginatorLeftText {
  color: #ed1846;
}
.paginatorCenterText {
  color: #000000;
}
.paginatorRightText {
  color: #000000;
}
</style>

<style>
.checkPManager {
  margin-right: 5px;
}
.checkPManager .p-checkbox-box {
  border: 2px solid #a0a0a0 !important;
  background: none !important;
}
.checkPManager .p-highlight {
  background: #ffffff !important;
}
.checkPManager .p-highlight .p-checkbox-icon {
  color: #458fff !important;
  font-weight: 900 !important;
}
.checkPManager .p-focus {
  box-shadow: 0px 0px 0.3rem #458fff !important;
}
.radioPManager{
    margin-right: 5px;
}
.radioPManager .p-radiobutton-box{
  border: 2px solid #A0A0A0 !important;
  background: none !important;
}
.radioPManager .p-highlight{
  background: #FFFFFF !important; 
}
.radioPManager .p-highlight .p-radiobutton-icon{
  background-color: #458FFF !important;
  font-weight: 900 !important;
}
.radioPManager .p-focus{
  box-shadow: 0px 0px 0.3rem #FFFFFF !important;
}

.dataTableManagerConsents.p-datatable .p-datatable-tbody > tr > td {
  border: none;
}
.text-align-def {
  text-align: center;
}
/* change text-align on small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .text-align-sm {
    text-align: right;
  }
  .mobileShow {
    display: none !important;
  }
  .mobileNotShow {
    display: flex !important;
  }
  .onMobileBreakLine {
    display: inline-block !important;
  }
  .paginatorWrapper {
    width: 30%;
  }
}
@media (max-width: 768px) {
  .mobileShow {
    display: flex !important;
  }
  .mobileNotShow {
    display: none !important;
  }
  .onMobileBreakLine {
    display: block !important;
  }
  .paginatorWrapper {
    width: 50%;
  }
}
.filterW122 {
  width: 122px;
}
.filterConsentsInputText {
  margin-left: 55px;
  font-size: 14px;
}
.filterConsentsClearIcon {
  font-size: 10px;
  margin-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.error-field {
  border-color: #fb3434 !important;
}
</style>